/* Museo Sans from TypeKit */
@import url("https://use.typekit.net/jbq6wyt.css");

:root {
  --primary-lightest: /*     */ hsl(217, 97%, 54%);
  --primary-lighter: /*      */ hsl(217, 100%, 50%);
  --primary: /*              */ hsl(217, 100%, 36%);
  --primary-darker: /*       */ hsl(217, 100%, 29%);
  --primary-darkest: /*      */ hsl(217, 100%, 22%);

  --secondary: /*            */ hsl(156, 100%, 40%);
  --secondary-lightest: /*   */ hsl(156, 65%, 59%);
  --secondary-lighter: /*    */ hsl(156, 67%, 49%);
  --secondary-darker: /*     */ hsl(156, 100%, 33%);
  --secondary-darkest: /*    */ hsl(156, 100%, 25%);

  --alternate: /*            */ hsl(49, 100%, 50%);
  --alternate-lightest: /*   */ hsl(49, 100%, 70%);
  --alternate-lighter: /*    */ hsl(49, 100%, 61%);
  --alternate-darker: /*     */ hsl(49, 100%, 39%);
  --alternate-darkest: /*    */ hsl(49, 100%, 30%);

  --primary-lightest-a: /*   */ hsla(217, 97%, 54%, 50%);
  --primary-lighter-a: /*    */ hsla(217, 100%, 50%, 50%);
  --primary-a: /*            */ hsla(217, 100%, 36%, 50%);
  --primary-darker-a: /*     */ hsla(217, 100%, 29%, 50%);
  --primary-darkest-a: /*    */ hsla(217, 100%, 22%, 50%);

  --secondary-a: /*          */ hsla(156, 100%, 40%, 50%);
  --secondary-lightest-a: /* */ hsla(156, 65%, 59%, 50%);
  --secondary-lighter-a: /*  */ hsla(156, 67%, 49%, 50%);
  --secondary-darker-a: /*   */ hsla(156, 100%, 33%, 50%);
  --secondary-darkest-a: /*  */ hsla(156, 100%, 25%, 50%);

  --alternate-a: /*          */ hsla(49, 100%, 50%, 50%);
  --alternate-lightest-a: /* */ hsla(49, 100%, 70%, 50%);
  --alternate-lighter-a: /*  */ hsla(49, 100%, 61%, 50%);
  --alternate-darker-a: /*   */ hsla(49, 100%, 39%, 50%);
  --alternate-darkest-a: /*  */ hsla(49, 100%, 30%, 50%);

  --highlight: hsl(200, 69%, 96%);
  --shadow: hsl(200, 69%, 3%);
  --inactive: hsl(288, 7%, 27%);
}

:root {
  --grid-columns: 3;
  --gap-size: 1em;
  --cell-size: calc(85vw / var(--grid-columns));
  --safe-size: min(90vw, 90vh);
}

@media screen and (max-width: 800px) {
  :root {
    --grid-columns: 3;
    --gap-size: 0em;
    --cell-size: calc(100vw / var(--grid-columns));
  }
}

@media screen and (max-width: 600px) {
  :root {
    --grid-columns: 2;
    --gap-size: 0em;
    --cell-size: calc(100vw / var(--grid-columns));
  }
}

a {
  color: var(--primary);
}
